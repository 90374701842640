@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

html,
body {
  margin: 0;
  padding: 0;
  max-height: 100%;
  font-family: "Roboto", sans-serif;
}

html {
  height: 100vh !important;
}

body {
  height: 98%;
}

code {
  font-family: "Roboto", sans-serif;
}

iframe {
  display: none;
}

.attributesCollapse.collapse.show .row {
  padding: 0px 0 10px 0;
}

.reactstrap-confirm > .modal-content > .modal-header > button.close {
  all: unset;
  cursor: pointer;
  transform: scale(1.3);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .collapse.show .row {
    padding: 10px 0 0 0;
  }
}

.k-shadow {
  box-shadow: 0px 3px 10px 2px rgb(0 0 0 / 8%);
}
