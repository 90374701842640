header {
  box-shadow: 0px 5px 6px -7px black;
  padding-bottom: 10px;
}

toolbar.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(70, 111, 161);
  height: 56px;
}

.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0.5rem 1rem 0 1rem;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_logo a {
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
}

.toolbar_logo a:hover {
  color: #000;
}

.toolbar_logo a img {
  max-width: 300px;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 2px 6px;
  display: flex;
  align-items: center;
}

.toolbar_navigation-items a {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 6px;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #0d73d3;
}

.toolbar_navigation-items li:last-child a:hover {
  color: #0d73d3;
}

.mobile_toolbar_login {
  display: none;
}

.ant-btn-primary,
.ant-btn-primary:hover {
  background-color: #0d73d3;
}

.ant-btn-primary a,
.ant-btn-primary a:hover {
  font-size: 18px;
  line-height: 0;
}

.bottom-header-nav {
  margin-right: 1rem;
}

.subMenuBar {
  justify-content: flex-start !important;
  box-shadow: 0px 6px 10px -4px #00000082;
  padding: 5px 25px 5px 15px;
}

.subMenuBar ul a li {
  margin: 0rem 2rem;
}

.hide-mobile {
  display: block;
}

.show-mobile {
  display: none;
}

/* .subMenuBar ul a:first-child li{
      margin: 1rem 5rem 1rem 1rem;
    } */

@media screen and (max-width: 760px) {
  .toolbar_navigation-items {
    display: none;
  }
  .toolbar_logo {
    margin-left: 4rem;
  }

  .toolbar_logo a img {
    width: 150px;
    margin-left: 0rem;
  }

  .toolbar_navigator {
    margin: 0 0.5em;
  }

  .mobile_toolbar_login {
    display: block;
    margin-left: 2rem;
  }

  .loginBtn {
    text-decoration: none;
    background: #000;
    box-shadow: none;
    border: none;
    color: #fff;
    padding: 5px 8px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Libre Franklin";
  }

  .loginBtn:active {
    color: #fff;
  }

  .loginBtn:visited {
    color: #fff;
  }
  .toolbar_navigator button.ant-btn {
    display: none;
  }
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }
  .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 98px;
    background: #fff;
  }
  .second-header {
    display: block !important;
  }
  .second-header .hide {
    display: none;
  }
  .second-header {
    padding: 30px 20px;
  }
  header #navbar {
    display: none;
  }
  .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    border-color: rgba(0, 0, 0, 0.1) !important;
  }
  .navbar-toggler svg{
    margin-top: 5px;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .analytika a {
    margin-right: 10px;
    text-decoration: none;
    color: rgb(0, 0, 0);
    text-shadow: none;
    font-weight: 500;
  }
  .analytika ul {
    list-style: none;
    padding-inline-start: 0;
    margin-bottom: 0;
  }
  .analytika a.active {
    font-weight: 500;
    color: #007bff;
  }
  .second-header, #navbar {
    padding: 30px 20px;
    box-shadow: 0px 6px 10px -4px #00000082;
  }
}

@media screen and (min-width: 759px) {
  .toggle-btn {
    display: none;
  }

  .toolbar_logo {
    margin-left: 0;
  }
}
