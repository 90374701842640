.toggle-button {
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  margin: 10px;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button-line {
  width: 30px;
  height: 2px;
  background: #000;
}

.toggle-button.ml-3 {
  margin-left: 1rem;
  width: 44px;
  height: 32px;
}

.cross {
  width: 30px;
  height: 2px;
  transition: 0.4s;
}

.cross.button-line-1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background: #000;
}

.cross.button-line-2 {
  opacity: 0;
}

.cross.button-line-3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  background: #000;
}
