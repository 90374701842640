.side-drawer {
  height: 100%;
  background: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1rem;
  padding-inline-start: 0;
}

.side-drawer li {
  margin: 2.5rem;
  text-align: center;
}

.side-drawer a {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #0557a2;
}

.side-drawer a img {
  width: 250px;
}

@media screen and (min-width: 759px) {
  .side-drawer {
    display: none;
  }
}
